import React, { Component } from "react"
import { Container, Row, Col, Form } from 'react-bootstrap';
import axios from 'axios'

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            company: '',
            email: '',
            firstName: '',
            lastName: '',
            phone: '',
            subject: '',
            message: '',
        }
    }

    handleSubmit(type) {
        if (type === 'contact' && this.state.company === '') {
            alert('Please enter your company!');
            return false;
        } else if (this.state.email === '') {
            alert('Please enter your email!');
            return false;
        } else if (this.state.firstName === '') {
            alert('Please enter your first name!');
            return false;
        } else if (type === 'contact' && this.state.lastName === '') {
            alert('Please enter your last name!');
            return false;
        } else if (type === 'contact' && this.state.phone === '') {
            alert('Please enter your phone!');
            return false;
        }

        axios({
            method: "POST",
            url: "https://getform.io/f/3cdc58b4-588f-4498-804b-63c30fae62e9",
            data: this.state
        }).then((response) => {
            console.log(response);
            alert("Message Sent.");
            this.resetForm()
            const isBrowser = () => typeof window !== "undefined"
            const path = isBrowser() && window.location.replace('/');
            /* if (response.data.status === 'success') {
                alert("Message Sent.");
                this.resetForm()
            } else if (response.data.status === 'fail') {
                if (response.data.error[0]) {
                    alert(response.data.error[0])
                } else {
                    alert("Something went wrong. Please try again later!")
                }
            } */
        })
    }

    resetForm() {
        this.setState({ company: '', firstName: '', lastName: '', email: '', phone: '', subject: '', message: '' })
    }
    render() {
        return (
            <footer>
                <section className="bg-theme px-xl-5">
                    <Container className="py-3 px-xl-5">
                        <Row>
                            <Col md="4">
                                <h3 className="text-white">Quick Links</h3>
                                <ul>
                                    <li><a href="/" className="text-white"><i className="fa fa-chevron-right"></i> Home</a></li>
                                    <li><a href="/about-us" className="text-white"><i className="fa fa-chevron-right"></i> About Us</a></li>
                                    <li><a href="/penetration-and-vulnerability-testing" className="text-white"><i className="fa fa-chevron-right"></i> Penetration and Vulnerability Testing</a></li>
                                    <li><a href="/consulting-services" className="text-white"><i className="fa fa-chevron-right"></i> Consulting Services</a></li>
                                    <li><a href="/cyber-health-check-chc" className="text-white"><i className="fa fa-chevron-right"></i> Cyber Health Check</a></li>
                                    <li><a href="/policy-development" className="text-white"><i className="fa fa-chevron-right"></i> Governance Policy Development</a></li>
                                    <li><a href="/contact-us" className="text-white"><i className="fa fa-chevron-right"></i> Contact Us</a></li>
                                </ul>
                            </Col>
                            <Col md="8">
                                <h2 className="text-center font-weight-bold text-white">HOW CAN WE HELP?</h2>
                                <div className="heading-titel-line my-3"></div>
                                <Form>
                                    <Row>
                                        <Col lg="4" md="6" className="my-2">
                                            <label htmlFor="company" className="text-white">Company Name <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="company"
                                                placeholder="Enter Your Company Name"
                                                onChange={e => this.setState({ company: e.target.value })} />
                                        </Col>
                                        <Col lg="4" md="6" className="my-2">
                                            <label htmlFor="email" className="text-white">Company Email <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="email"
                                                placeholder="Enter Your Company Email"
                                                onChange={e => this.setState({ email: e.target.value })} />
                                        </Col>
                                        <Col lg="4" md="6" className="my-2">
                                            <label htmlFor="firstName" className="text-white">First Name <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="firstName"
                                                placeholder="Enter Your First Name"
                                                onChange={e => this.setState({ firstName: e.target.value })} />
                                        </Col>
                                        <Col lg="4" md="6" className="my-2">
                                            <label htmlFor="lastName" className="text-white">Last Name <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="lastName"
                                                placeholder="Enter Your Last Name"
                                                onChange={e => this.setState({ lastName: e.target.value })} />
                                        </Col>
                                        <Col lg="4" md="6" className="my-2">
                                            <label htmlFor="phone" className="text-white">Phone <span className="text-danger">*</span></label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="phone"
                                                placeholder="Enter Your Phone"
                                                onChange={e => this.setState({ phone: e.target.value })} />
                                        </Col>
                                        <Col lg="4" md="6" className="my-2">
                                            <label htmlFor="subject" className="text-white">Subject</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="subject"
                                                placeholder="Enter Your Phone" onChange={e => this.setState({ subject: e.target.value })} />
                                        </Col>
                                        <Col lg="12" className="my-2">
                                            <label htmlFor="message" className="text-white">Your Message</label>
                                            <textarea
                                                className="form-control"
                                                id="message"
                                                placeholder="Enter Your Message" onChange={e => this.setState({ message: e.target.value })} ></textarea>
                                        </Col>
                                        <Col lg="12" className="my-2">
                                            <button type="button" onClick={() => this.handleSubmit('contact')}
                                                className="form-control btn btn-primary btn-submit" >SUBMIT</button>
                                        </Col>
                                    </Row>
                                </Form>

                            </Col>
                        </Row>
                    </Container>
                </section>
                <section style={{backgroundColor: '#0066ff'}}>
                    <Container>
                        <Row>
                            <Col >
                                <p className="text-center p-3 text-white mb-0">Developed By ESG Security</p>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </footer>
        )
    }
}
export default Footer